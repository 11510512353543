import $ from "jquery";
import "slick-carousel";

export default class UIBindings {
	slickCarourel() {
        let carousel = $('[data-slick]');
        let customPaging = function(slide, i){
            return $('<span class="cursor-pointer px-1"><i class="fas fa-circle fa-xs slick-dot active"/><i class="far fa-circle fa-xs slick-dot"/></span>');
        }
        if (carousel.length > 0) {
            carousel.slick({customPaging: customPaging});
        }
    }
}